export const ADMIN_PAGE = 'admin-view'
export const SIGNATURE_PAGE = 'purpose-signature'
export const SENTIMENT_DASHBOARD_PAGE = 'sentiment-dashboard'

export const ADMIN_ROLE = 'Admin'
export const COMPANY_VIEW_ROLE = 'CompanyView'
export const DEPARTMENT_VIEW_ROLE = 'DepartmentalView'
export const MANAGER_ROLE = 'TeamView'
export const USER_ROLE = 'User'

export const ROLES = {
  1: ADMIN_ROLE,
  2: COMPANY_VIEW_ROLE,
  3: DEPARTMENT_VIEW_ROLE,
  4: MANAGER_ROLE,
  5: USER_ROLE,
} as const

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_DISPLAY = 'MM/DD'

export const DISPLAY_MODE_LOCAL_STORAGE_KEY = 'darwin-display-mode'

export const APP_BAR_HEIGHT = '125px'
export const DRAWER_WIDTH = '300px'
export const FOOTER_HEIGHT = '80px'
export const CHAT_DRAWER_WIDTH = 544 // px

export const FONT_FAMILY = 'Lato, Raleway, sans-serif'
