import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Backdrop from './Components/backdrop'
import ReputationReport from './pages/reputation-report'

const AdminPage = React.lazy(() => import('./pages/admin'))
const Dashboard = React.lazy(() => import('./pages/dashboard'))
const ChangePassword = React.lazy(
  () => import('./pages/registration/change-password'),
)
const NewPassword = React.lazy(
  () => import('./pages/registration/new-password'),
)
const ResetPassword = React.lazy(
  () => import('./pages/registration/reset-password'),
)
const SignIn = React.lazy(() => import('./pages/registration/sign-in'))

export const AppRoutes = () => {
  return (
    <Suspense fallback={<Backdrop />}>
      <Routes>
        <Route index element={<SignIn />} />
        <Route path={'/admin/*'} element={<AdminPage />} />
        <Route path={'/sign-in'} element={<SignIn />} />
        <Route path={'/reset-password'} element={<ResetPassword />} />
        <Route
          path={`/new-password/:userId/:token`}
          element={<NewPassword />}
        />
        <Route path={'/change-password'} element={<ChangePassword />} />
        <Route path={`/dashboard/*`} element={<Dashboard />} />
        <Route
          path="/reputation-report/:clientId/:industryId"
          element={<ReputationReport />}
        />
      </Routes>
    </Suspense>
  )
}
