import dayjs, { Dayjs } from 'dayjs'
import { Maybe } from 'graphql/jsutils/Maybe'
import { ReactNode, useState } from 'react'
import { TimeSeriesContext } from '../Context'

export const TimeSeriesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [startDate, setStartDate] = useState<Maybe<Dayjs>>(
    dayjs().subtract(91, 'day'),
  )
  const [endDate, setEndDate] = useState<Maybe<Dayjs>>(
    dayjs().subtract(1, 'day'),
  )

  return (
    <TimeSeriesContext.Provider
      value={{ startDate, endDate, setStartDate, setEndDate }}
    >
      {children}
    </TimeSeriesContext.Provider>
  )
}
